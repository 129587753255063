<template>
  <div id="">
    <v-text-field
      label="Cardholder Name"
      v-model="cardName"
    ></v-text-field>
    <div id="example">
      <div class="" id="tappay-iframe"></div>
      <div class="trust pt-3" id="trust">
        <img src="@/assets/cc/visa.svg" height="25"/>
        <img src="@/assets/cc/mastercard.svg" height="25"/>
        <img src="@/assets/cc/jcb.svg" height="25"/>
      </div>
      <div class="trust-2 py-2">
        <v-layout  wrap class="green-text">
          <v-flex xs12 color="green">
            <span>
              <v-icon color="green">done</v-icon>
              All transmissions are secure
            </span>
          </v-flex>
          <v-flex xs12 >
            <span>
              <v-icon color="green">done</v-icon>
              We use SSL Encryption verification
            </span>
          </v-flex>
          <v-flex xs12>
            <span color="green">
              <v-icon color="green">done</v-icon>
              Your personal information and card details are encrypted and securely stored
            </span>

          </v-flex>
        </v-layout>
      </div>
      <div class="">
        <div class="">
          {{cardError}}
        </div>
        <div class="" v-if="sending">

          <div class="">
            <span >We're processing your payment. Please wait a moment...</span>
          </div>
          <div class="">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
        <!-- <v-btn color="rgb(255,192,0)" dark :disabled="sending" @click="getPrime()" v-if="visit">Request visit</v-btn> -->
        <v-btn color="primary" :disabled="sending" @click="getPrime()">Confirm payment</v-btn>
        <v-checkbox label="Save Card" v-model="remember"></v-checkbox>

      </div>

    </div>
  </div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import axios from 'axios'
import * as types from '../../store/types'


export default {
  name: "",
  props: ['amount','comment'],
  data: () => ({
    remember:true,
    cardError:"",
    sending: false,
    cardName:"",
  }),
  methods: {
    getPrime() {
      this.cardError=""
      this.sending = true
      TPDirect.card.getPrime((result) => {
        try{
        console.log(result.card.prime)
        console.log(this.user.phone);
        if(!this.user.phone){
          console.log('noPhone');
        }
        // alert(result.card.prime);
        var info={
          "prime": result.card.prime,
          "amount":this.amount,
          "comment": this.comment,
          "phone": this.user.phone,
          "name": this.cardName,
          "email": this.user.email,
          "remember": this.remember,
        }
        // axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sandboxPayByPrime', info, {
        axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/payByPrime', info, {
            headers: { 'Content-Type': 'text/plain' }
        })
        .then(res=>{

          if (res.data.status==0 ){
            this.$emit('success')
            console.log('Response data: ',res.data);
            if (this.remember){
              axios.patch('users/' + this.user.id + '.json?auth=' + this.$store.state.auth.idToken, {card:res.data.card_secret, saveCard:true})
            }
          } else {
            this.$emit('fail', res.data)
            console.log('Response data: ',res.data);
            this.sending=false
          }
        })
      }
      catch(err){
        this.sending=false
        this.cardError="Something strange happened. Please get in touch with My Room Abroad staff."
      }
      })
    },

  },
  mounted() {
    //do something after mounting vue instance
    TPDirect.card.setup('#tappay-iframe')
    // console.log(TPDirect.card);
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  }
}
</script>
<style lang="scss" scoped>
#tappay-iframe {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  padding: .578571em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color .1s ease, border-color .1s ease;
  transition: color .1s ease, border-color .1s ease;
  width: 500px;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #00bfff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}


</style>

<template>
<div id="">
  <!-- <div class="fb-customerchat" id="fbChatHead" :class="{fbDisplay:'hide'}" page_id="1880520158897576"></div> -->
  <!-- <div class="fb-customerchat"
    attribution=setup_tool
    page_id="1880520158897576"
    theme_color="#00bfff"
    logged_in_greeting="Hi! How can we help you?"
    logged_out_greeting="Hi! How can we help you?">
  </div> -->

  <Navbar></Navbar>
  <v-container grid-list-xl>

    <v-layout  wrap>
    <v-flex xs12 sm7 order-sm1>
      <v-card>
        <v-card-title primary-title>
          <v-layout  wrap  justify-center>
              <v-flex xs12>
                <h1 class="headline">{{status}}</h1>
              </v-flex>
              <v-flex xs12 class="text-xs-center">

              </v-flex>
            </v-layout>
          <br>
        </v-card-title>
        <div class="" v-if="bookingRequest.status=='Requested'">

        <v-card-text>
          <Countdown class="text-xs-center pa-bottom" :end="endDate" v-if="endDate!=''"></Countdown>

          {{$t("userRequest.reqText")}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="cancelDialog=true">{{$t("userRequest.cancel")}}</v-btn>
        </v-card-actions>
      </div>

      <!-- ACCEPTED REQUEST -->
      <div class="" v-else-if="bookingRequest.status=='Accepted'">

      <v-card-text>
        {{$t("userRequest.acceptText")}}

        <CardPayment :amount="total" :comment="'First Rent' + order.fullRoomNumber" @fail="fail" @success="success" ></CardPayment>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" @click="payByCard=true">Pay by card</v-btn> -->
        <v-btn color="primary" flat :href="'/payment/'+ bookingRequest.orderId">{{$t('userRequest.invoice')}}</v-btn>
      </v-card-actions>

    </div>


<!-- CANCELED -->
    <div class="" v-else-if="bookingRequest.status=='Canceled'">


      <h1 class="text-xs-center">CANCELED</h1>
    </div>


    <!-- Past request -->
    <div class="" v-else-if="bookingRequest.status=='Past'">
      <v-card-text>
        <p><span class="my-alert">{{$t("userRequest.pastText")}}</span></p>
      </v-card-text>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn color="primary" flat :href="'/search?mid='+suggestMid+'&mod='+suggestMod">{{$t('userRequest.declBtn2')}}</v-btn>

      </v-card-actions>
    </div>
    <!-- Declined request -->
    <div class="" v-else-if="bookingRequest.status=='Declined'">

      <v-card-text v-if="bookingRequest.reason=='' || bookingRequest.reason==undefined">
        <p><span class="my-alert">{{$t("userRequest.declTitle")}}</span>
          <br>{{$t("userRequest.declText1")}}
          <br>{{$t("userRequest.declText3")}}
          <br>{{$t("userRequest.declText4")}}</p>
      </v-card-text>
      <v-card-text v-else>
        <p><span class="my-alert">{{$t("userRequest.declTitle")}}</span>
          <br> <span v-if="bookingRequest.reason=='booked'">{{$t("userRequest.declText5")}}</span>
          <br>{{$t("userRequest.declText2")}}
          <br><span v-if="bookingRequest.suggest!=''">{{$t("userRequest.declText6")}} <v-btn flat color="primary" :href="bookingRequest.suggest+'?mid='+ suggestMid +'&mod='+suggestMod">{{$t("userRequest.declBtn1")}}</v-btn></span>
          <span v-else-if="bookingRequest.sMid!=undefined">The landlord suggests you book the same room for these dates: from {{bookingRequest.sMid | date}} till {{bookingRequest.sMod | date}}
            <v-btn flat color="primary" :href="'bookingRequest/'+bookingRequest.listingId+'/'+dateLink+'/'+bookingRequest.type">{{$t("userRequest.declBtn1")}}</v-btn></span>

        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat :href="'/search?mid='+ suggestMid +'&mod='+suggestMod">{{$t('userRequest.declBtn2')}}</v-btn>
      </v-card-actions>
    </div>
    <!-- PAID REQUEST -->
    <div class="" v-else>

      <v-card-text>
        {{$t("userRequest.paidText")}}
        <br>
        <v-btn color="primary" href='/mybooking'>Go to My Booking Page</v-btn>
        <br>
        {{$t("userRequest.llName")}}:
        <span v-if="landlord.name!=undefined && landlord.name!=''"> {{landlord.name}}</span>
         <span v-else> {{landlord.userName}}</span>
        <br>
        {{$t("userRequest.llEmail")}}<a :href="'mailto:'+landlord.email+'?subject=Nice%20to%20meet%20you'" target="_top">{{landlord.email}}</a>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat :href="'https://maps.google.com/?q='+order.location.lat+','+order.location.lng" target="_blank">{{$t('userRequest.directions')}}</v-btn>
      </v-card-actions>
    </div>
    <v-card-actions>
      <v-btn color="error" @click="removeRequest" v-if="user.roleValue>80">Delete Room!!</v-btn>
    </v-card-actions>

    </v-card>
    <v-layout  justify-center>
      <v-dialog v-model="cancelDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">{{$t("userRequest.cancelTitle")}}</v-card-title>
          <v-card-text>{{$t("userRequest.cancelText")}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click.native="cancelDialog = false">{{$t("userRequest.cancelKeep")}}</v-btn>
            <v-btn color="error" flat @click.native="cancelRequest">{{$t('userRequest.cancel')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    </v-flex>
    <v-flex xs12 sm5 >
      <v-card>
        <v-img
        :src="thumbnailUrl"
        alt="room thumbnail"
        ></v-img>
        <v-card-title primary-title>
          <v-layout  wrap>
            <v-flex xs12>
              <h3 class="headline">{{room.general.title}}</h3>
            </v-flex>
            <v-flex xs8>
              <span>{{contractLength}}</span>
            </v-flex>
            <v-flex xs4 class="text-xs-right">
              {{couple}}
            </v-flex>
            <v-flex xs12>
              <span>
                {{contractFlexibility}}
              </span>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout  wrap align-center>
            <v-flex xs12 class="title">
              {{$t("userRequest.contractDates")}}
            </v-flex>
            <v-flex xs5 class="lighten-1">
              {{$t("userRequest.mid")}}<br>
              <span class="subheading">{{datesFormatted.mid}}</span>
            </v-flex>
            <v-flex xs2>
              <v-icon>arrow_forward</v-icon>
            </v-flex>
            <v-flex xs5 class="text-xs-right">
              {{$t("userRequest.mod")}}<br>
              <span class="subheading">{{datesFormatted.mod}}</span>

            </v-flex>
            <v-flex xs1>

            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <v-layout  wrap>
            <v-flex xs12 class="title">
              {{$t("userRequest.payment")}}
            </v-flex>
            <v-flex xs12 class="subheading">
              {{$t("userRequest.toMRA")}}
            </v-flex>
            <v-flex xs9>
              {{$t("userRequest.firstRent")}}
            </v-flex>
            <v-flex xs3 class="text-xs-right">
              {{roomPrice}} NT$
            </v-flex>
            <v-flex xs9 v-if="utilities>0">
              {{$t("billing.utilities")}}
            </v-flex>
            <v-flex xs3 class="text-xs-right" v-if="utilities>0">
              {{utilities}} NT$
            </v-flex>
            <v-flex xs9 v-if="couple=='Couple'">
              {{$t("userRequest.couplesSurcharge")}}
            </v-flex>
            <v-flex xs3 class="text-xs-right" v-if="couple=='Couple' && room.hasOwnProperty('billing')">
              {{room.billing.couplesExtraUtilities}} NT$
            </v-flex>
            <v-flex xs9>
              <v-tooltip bottom>
                <span slot="activator">{{$t("userRequest.fee")}} <span class="MRAIcon_info my-tooltip"></span></span>
                <span>{{$t("userRequest.feeHelp1")}}
                  <br>{{$t("userRequest.feeHelp2")}}
                  <br>{{$t("userRequest.feeHelp3")}}
                  <br>{{$t("userRequest.feeHelp4")}}
                </span>
              </v-tooltip>
            </v-flex>
            <v-flex xs3 class="text-xs-right">
              {{commission}} NT$
            </v-flex>
            <v-flex xs9 v-if="discount!=0">
              {{$t("userRequest.discount")}}
            </v-flex>
            <v-flex xs3 class="text-xs-right" v-if="discount!=0">
              {{bookingRequest.discount}} NT$
            </v-flex>
            <v-flex xs9>

            </v-flex>
            <v-flex xs3>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs9 class="body-2">
              {{$t("userRequest.total")}}:
            </v-flex>
            <v-flex xs3 class="text-xs-right body-2">
              {{total}} NT$
            </v-flex>
            <!-- <v-flex xs9 class="body-2">
              {{$t("userRequest.total")}} {{bookingRequest.currency}}
            </v-flex> -->
            <!-- <v-flex xs3 class="text-xs-right body-2" v-if="order.currency!='TWD'">
              <h4>{{totalFx}} {{bookingRequest.currency}}</h4>
            </v-flex> -->
            <v-flex xs12 class="caption">
              {{$t("userRequest.charge")}}
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    </v-layout>


    </v-container>
</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import axios from 'axios'
import * as types from '../../store/types'
import moment from 'moment'
import AWSSES from '../../aws-ses'
import firebase from '../../FirebaseApp'
import Countdown from 'vuejs-countdown'
import CardPayment from '../shared/CardPayment.vue'


export default {
  name: "",
  props: [],
  data: () => ({
    bookingRequest: {status:"Requested"},
    contractDetails: {},
    order:{ location:{}},
    link:{},
    liveUser:{},
    room:{ general:{}},
    datesFormatted: {
      mid: "",
      mod: "",
    },
    endDate:"",
    thumbnailUrl: "",
    couple: "",
    fx: "",
    serviceFee: .20,
    total: '',
    totalFx: '',
    discount:0,
    commission: '',
    utilities:0,
    status: "",
    deadline: "",
    landlord:{},
    cancelDialog: false,
    newBookingRequest: {},
    updateUser: true,
    admin: {
      email: 'hello@myroomabroad.com',
      name: 'Admin'
    },
    suggestDates:"",
    payByCard:false,
    roomPrice:0,

  }),
  computed: {
      ...mapGetters({
        user: types.GET_USER
      }),
    transactionCost(){
      let t=.028
      return t
    },
    dateLink(){
      if (this.bookingRequest.sMid!=undefined && this.bookingRequest.sMod!=undefined){
        return moment(this.bookingRequest.sMid).format('x')+'/'+moment(this.bookingRequest.sMod).format('x')
      } else {
        return ''
      }
    },
    contractLength:function(){
      switch(this.contractDetails.contractLength){
        case "1 month":
          return this.$t('contractDetails.minLengthOptions.one')+' '+this.$t('details.contract')
        case "3 months":
          return this.$t('contractDetails.minLengthOptions.three')+' '+this.$t('details.contract')
        case "5 months":
          return this.$t('contractDetails.minLengthOptions.five')+' '+this.$t('details.contract')
        case "12 months":
          return this.$t('contractDetails.minLengthOptions.twelve')+' '+this.$t('details.contract')
        case "Open Contract":
          return this.$t('contractDetails.minLengthOptions.open')
        default:
         return ""
      }
    },
    contractFlexibility:function(){
      switch (this.contractDetails.contractFlexibility){
        case "Starts and ends any day":
          return this.$t('contractDetails.contractFlexibilityOptions.any')
        case "Starts any day for 1 month":
          return this.$t('contractDetails.contractFlexibilityOptions.oneMonth')
        case "Starts first or 15th":
          return this.$t('contractDetails.contractFlexibilityOptions.firstOr')
        case "Starts always on the first":
          return this.$t('contractDetails.contractFlexibilityOptions.first')
        default:
          return ""
      }
    },
    suggestMid: function(){
      return new Date(this.bookingRequest.realDates.mid).getTime()
    },
    suggestMod: function(){
      return new Date(this.bookingRequest.realDates.mod).getTime()
    }


  },
  watch: {
  },
  // firebase() {
  //   return {
  //     liveUser: {
  //       source: firebase.database().ref('users/' + this.user.id),
  //       asObject: true,
  //       readyCallback: function() {
  //         console.log(this.liveUser);
  //         this.updateUser = !this.updateUser
  //       }
  //     },
  //   }
  // },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 150,
        behavior: "smooth"
      })
    },
    setPrice(){
      if (this.liveUser.hasOwnProperty('couple') && this.liveUser.couple.toLowerCase() == "couple" && this.room.hasOwnProperty('billing')){
        this.roomPrice=this.bookingRequest.price-this.room.billing.couplesExtraUtilities
      } else {
        console.log(this.room,this.room.hasOwnProperty('billing'), 'couplecheck');
        this.roomPrice=this.bookingRequest.price
      }

    },
    updateLiveUser(){
      let vThis=this
      console.log('update liveUser')
      if (this.liveUser.bookingRequestOut && this.liveUser.bookingRequestOut != "") {
        axios.get('/bookingRequests/' + this.liveUser.bookingRequestOut + '.json')
          .then(res => {
            // console.log('got the request')
            // console.log(res)
            vThis.bookingRequest = res.data
            axios.get('users/'+vThis.bookingRequest.landlordId+".json")
            .then(res=>{
              vThis.landlord=res.data
            })
            if (vThis.bookingRequest.status == "Requested" && (new Date(vThis.bookingRequest.dateRequested).getTime() + 48 * 60 * 60 * 1000) < new Date().getTime()) {
              axios.patch('/bookingRequests/' + vThis.bookingRequest.id + '.json' + '?auth=' + vThis.$store.state.auth.idToken, {
                status: "Past"
              })
              .catch(err=>{
                console.log('error patching the booking Requests:', err);
              })
              vThis.bookingRequest.status = "Past"
            } else if (vThis.bookingRequest.status == "Requested") {
              vThis.status = this.$t("userRequest.reqStatus")
              vThis.endDate=moment.tz(vThis.bookingRequest.dateRequested, moment.tz.guess()).add(2,'d').format('llll')
              // console.log(vThis.endDate, 'End date:')
            } else if (vThis.bookingRequest.status == "Declined") {
              vThis.status = this.$t("userRequest.declStatus")
            } else if (vThis.bookingRequest.status == "Accepted") {
              vThis.status = this.$t("userRequest.acceptStatus")
              axios.get('completedOrders/'+vThis.bookingRequest.orderId+'.json')
              .then(res=>{
                vThis.order=res.data
                if (vThis.order.status=="Paid"){
                  vThis.bookingRequest.status="Paid"
                  // console.log('inside paid');
                  vThis.status = this.$t("userRequest.paidStatus")
                }
              })
              .catch(err=>{
                console.log('error getting the orders:', err);
              })

            } else if (vThis.bookingRequest.status == "Past") {
              vThis.status = this.$t("userRequest.pastStatus")
            }
            vThis.datesFormatted.mid = moment(vThis.bookingRequest.dates.mid).format('ll')
            vThis.datesFormatted.mod = moment(vThis.bookingRequest.dates.mod).format('ll')
            if (vThis.liveUser.couple.toLowerCase() == "couple") {
              vThis.couple = this.$t("userRequest.couple")
            } else {
              vThis.couple = this.$t("userRequest.single")
            }
            if(vThis.bookingRequest.discount&&vThis.bookingRequest.discount!=0){
              vThis.discount=parseInt(vThis.bookingRequest.discount)
            }
            if (vThis.bookingRequest.type == "room") {
              axios.get('/rooms/' + vThis.bookingRequest.listingId + '.json')
                .then(res => {
                  vThis.room = res.data
                  // console.log('The dAte:', res.data)
                  vThis.fx = vThis.bookingRequest.fx
                  vThis.calculatePrices()

                  if(vThis.room.visual.photos!=undefined){
                    vThis.thumbnailUrl = vThis.room.visual.photos[0].url

                  }
                  axios.get('/apartments/'+vThis.room.apartmentId+'/contractDetails.json')
                  .then(res=>{
                    vThis.contractDetails=res.data
                  })
                  .catch(err=>{
                    console.log('error getting the apartments:', err);
                  })

                })
                .catch(err=>{
                  console.log('error getting the rooms:', err);
                })

            } else if (vThis.bookingRequest.type == "studio") {
              axios.get('/studios/' + vThis.bookingRequest.listingId + '.json')
              .then(res => {
                // console.log(res)
                vThis.room = res.data
                vThis.fx = vThis.bookingRequest.fx
                vThis.contractDetails=res.data.contractDetails

                // console.log(res.data)
                vThis.calculatePrices()
                vThis.thumbnailUrl = res.data.visual.photos[0].url
              })
              .catch(err=>{
                console.log('error getting the studios:', err);
              })

            } else if (vThis.bookingRequest.type== "apartment")
            axios.get('/wholeApts/' + vThis.bookingRequest.listingId + '.json')
            .then(res => {
              // console.log(res)
              vThis.room = res.data
              vThis.fx = vThis.bookingRequest.fx

              vThis.contractDetails=res.data.contractDetails
              // console.log(res.data)
              vThis.calculatePrices()
              vThis.thumbnailUrl = res.data.visual.photos[0].url
            })
            .catch(err=>{
              console.log('error getting the wholeApts:', err);
            })


          })
          .catch(err=>{
            console.log('error getting the booking Request:', err);
          })

      } else {
        vThis.bookingRequest = {status:"Requested"}
        vThis.thumbnailUrl = ""
        vThis.fx = 0
        vThis.calculatePrices()
        vThis.room = {}
      }

    },


    success(){
      alert('Payment successful')
      let vm=this
      axios.patch('users/'+this.order.tenantId+'.json?auth='+this.$store.state.auth.idToken,{orderPaid:true})
      axios.patch('completedOrders/'+vm.bookingRequest.orderId+'.json?auth='+vm.$store.state.auth.idToken,{payIn:true,status:'Paid'})
      .then(()=>{
        axios.get('users/'+vm.order.landlordId+'/sendChinese.json')
        .then(res=>{
          if (res==true) {
            AWSSES.emailing(vm.order.landlord, 'ZHLLPaymentReceived')
            AWSSES.emailing(vm.order.tenant, 'TPaymentReceivedZHLL')
          } else {
            AWSSES.emailing(vm.order.landlord, 'LLPaymentReceived')
            AWSSES.emailing(vm.order.tenant, 'TPaymentReceivedENLL')
          }
        })
        // AWSSES.emailing(vm.admin, 'APaymentReceived')
        // AWSSES.emailing(vm.order.tenant, 'TPaymentReceived')

      })
    },
    fail(data){
      alert('The transaction Failed, Please get in touch with MRA staff on hello@myroomabroad.com')
      axios.patch('completedOrders/'+this.bookingRequest.orderId+'.json?auth='+this.$store.state.auth.idToken,{payIn:false,status:'Failed Payment',data:data})
      .then(()=>{
        // AWSSES.emailing(this.admin, 'APaymentFailed')
        AWSSES.emailing(this.order.tenant, 'TPaymentFailed')
      })
    },
    calculatePrices() {
      // console.log('the room: ', this.room);
      if (this.bookingRequest.utilities){
        this.utilities=this.bookingRequest.utilities
      }
      this.setPrice()
      let roomPrice = Number(this.bookingRequest.price)
      this.fxPrice = Math.ceil(this.fx * roomPrice)
      this.commission = Math.ceil((((roomPrice+this.utilities) * this.serviceFee)+ this.utilities + roomPrice-this.discount) * this.transactionCost + ((roomPrice+this.utilities) * this.serviceFee))
      this.total = roomPrice + this.commission-this.discount+this.utilities
      this.totalFx = Math.ceil(this.total * this.fx)
    },
    cancelRequest() {
      let vThis=this
      axios.get('/bookingRequests/' + this.bookingRequest.id + '.json')
      .then(res=>{
        let brTest=res.data
        if (brTest.status=='Requested'){
          axios.patch('/bookingRequests/' + this.bookingRequest.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {
            status: "Canceled"
          })
          .then(() => {
            let updateRooms = vThis.room.general
            updateRooms.nextAvailability=vThis.bookingRequest.oldNextAv
            if (vThis.bookingRequest.type == "room") {
              axios.patch('/rooms/' + vThis.bookingRequest.listingId + '/general.json' + '?auth=' + vThis.$store.state.auth.idToken, updateRooms)
            } else if (vThis.bookingRequest.type == "studio") {
              axios.patch('/studios/' + vThis.bookingRequest.listingId + '/general.json' + '?auth=' + vThis.$store.state.auth.idToken, updateRooms)
            }
            axios.patch('/users/' + vThis.user.id + '.json' + '?auth=' + vThis.$store.state.auth.idToken, {
              bookingRequestOut: ""
            })
            .catch(err=>{
              console.log('error patching the user:', err);
            })

            AWSSES.emailing(vThis.user, 'TBookingRequestCanceled')
            // AWSSES.emailing(vThis.admin, 'ABookingRequestCanceled')
            axios.get('users/' + vThis.bookingRequest.landlordId + '.json')
            .then(res => {
              if (res.data.sendChinese){
                AWSSES.emailing(res.data, 'ZHLLBookingRequestCanceled')
              } else {
                AWSSES.emailing(res.data, 'LLBookingRequestCanceled')
              }
              vThis.$router.push({
                name: 'roomSearch'
              })
            })
            .catch(err=>{
              console.log('error getting the users:', err);
            })

          })
          .catch(err=>{
            console.log('error patching the BR:', err);
          })

        } else {
          alert('Something happened, please refresh your page.')
        }
      })
      .catch(err=>{
        alert('Something happened, please refresh your page.')
      })

    },
    removeRequest() {
      axios.patch('/bookingRequests/' + this.bookingRequest.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {
        status: "Canceled"
      })
      .catch(err=>{
        console.log('error patching the BR:', err);
      })

      axios.patch('/users/' + this.user.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {
          bookingRequestOut: ""
        })
        .then(() => {
          this.$router.push({
            name: 'roomSearch'
          })
        })
        .catch(err=>{
          console.log('error patching the user:', err);
        })

    },
  },
  created(){
    this.scrollTo()
    let id=this.user.id
    if (this.$route.query.id!=undefined && this.user.roleValue>70){
      id=this.$route.query.id
    }
    axios.get('users/'+id+'.json')
    .then(res=>{
      this.liveUser=res.data
      this.liveUser['.key']=id
      this.updateLiveUser()
      console.log(this.liveUser, 'is the user');
    })
  },
  components:{
    CardPayment,
    Countdown
  },
  filters:{
    dates(t){
      if (moment(t).isValid()){
        return moment(t).format("ll")
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.my-card-media {
    max-height: 250px;
    overflow: hidden;
}
.my-card-media img {
    object-fit: cover;
    object-position: center;
}
.my-card-title {
    color: rgba(0,0,0,.87);
    font-size: 1.25rem;
}
.md-card-content {
    color: rgba(0,0,0,.6);
}
.md-card-header {
    color: rgba(0,0,0,.6);
}
.divider {
    margin: 1rem;
}
.subheader {
    margin-top: -1rem;
    margin-bottom: 0.5rem;
}
.my-tooltip {
    position: relative;
    display: inline-block;
}
/* my-tooltip text */
.my-tooltip .my-tooltiptext {
    visibility: hidden;
    width: 350px;
    background-color: rgba(0,0,0,.87);
    color: #fff;
    size: 0.75em;
    padding: 10px;
    border-radius: 6px;
    font-family: 'Roboto', 'Open Sans', 'Sans-serif';
    /* Position the tooltip text - see examples below! */
    position: absolute;
    line-height: 1.3em;
    top: -50px;
    left: -150px;
    z-index: 1;
}
.my-tooltip:hover .my-tooltiptext {
    visibility: visible;
}
.price-tag {
    font-size: 0.875rem;
}
.price {
    font-size: 1.5rem;
}
.sentence {
    margin: auto;
}
.md-right {
    text-align: right;
}
.paymentDetails div {
    text-align: right;
}
.price-div div {
    text-align: right;
}
.payment-details {
    padding-top: 0.5em;
    line-height: 2em;
}
.deadline {
    font-size: 4rem;
}
.status-card {
    width: 100%;
    padding: 2rem;
}
.my-alert {

}
.my-title {
    margin-top: 1em;
    margin-left: 1em;
}
.my-actions {
    margin-top: 1.2em;
}
.margin-right {
    margin-right: 1em !important;
}
.pa-bottom{
  padding-bottom: 2rem;
}

@media only screen and (max-width: 960px) {
    .validate {
        bottom: 30px;
    }
    .group-flex {
        display: flex;
    }
    .deadline {
        font-size: 2rem;
    }
    .primary-part {
        order: 1;
    }
    .summary-part {
        order: 2;
    }
}
</style>
